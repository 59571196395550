import { CircularProgress, Unstable_Grid2 as Grid2, Typography } from '@mui/material';
import type { FC } from 'react';
import {
  DARK_INTERACTIVE_ACTIVE,
  DARK_INTERACTIVE_SECONDARY_PRESSED,
  LIGHT_INTERACTIVE_ACTIVE,
  LIGHT_INTERACTIVE_SECONDARY_PRESSED,
  muiTheme,
} from 'shared/theme';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
  text?: boolean;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 'medium', text }) => {
  let circSize;
  switch (size) {
    case 'small':
      circSize = 18;
      break;
    case 'large':
      circSize = 104;
      break;
    default:
      circSize = 56;
      break;
  }

  const spinner = (
    <Grid2 container position="relative" alignItems="center" justifyContent="center">
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme =>
            theme.palette.mode === 'light' ? LIGHT_INTERACTIVE_SECONDARY_PRESSED : DARK_INTERACTIVE_SECONDARY_PRESSED,
        }}
        size={circSize}
        thickness={3.5}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme => (theme.palette.mode === 'light' ? LIGHT_INTERACTIVE_ACTIVE : DARK_INTERACTIVE_ACTIVE),
          position: 'absolute',
          left: 0,
        }}
        size={circSize}
        thickness={3.5}
      />
    </Grid2>
  );

  return text ? (
    <Grid2
      container
      flexDirection={size === 'small' ? 'row' : 'column'}
      gap={muiTheme.spacing(1)}
      alignItems="center"
      justifyContent="center"
    >
      {spinner}

      {text && <Typography variant={'body2'}>Loading</Typography>}
    </Grid2>
  ) : (
    spinner
  );
};
