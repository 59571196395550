import { useAuth0 } from '@auth0/auth0-react';
import { Unstable_Grid2 as Grid2 } from '@mui/material';
import { LoginError } from 'App/Login/LoginError';
import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from './AuthContext';
import { getBoolean } from 'core/utils/saferGet';
import { LoadingSpinner } from 'shared/elements/LoadingSpinner/LoadingSpinner';
const paths = ['', '/', '/login', '/logout', '/welcome', '/signup'];
const PREV_ROUTE = 'PREV_ROUTE';

export const AuthRoute: FC<{ element: React.ReactNode }> = ({ element }) => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const { user, logout, isLoading, error } = useAuth();

  const prevRoute = window.localStorage.getItem(PREV_ROUTE);
  const pathname = location.pathname;

  const handleRedirect = () => {
    if (!prevRoute && pathname && !paths.includes(pathname)) {
      window.localStorage.setItem(PREV_ROUTE, pathname);
    }

    if (pathname === '/signup') {
      // Parse the URL to extract query parameters
      const currentUrl = new URL(window.location.href);

      // Access specific query parameters and passon to loginWithRedirect
      // Reference - https://auth0.github.io/auth0-react/interfaces/AuthorizationParams.html
      // The query parameters, other than screen_hint, become available to Auth0 post-login
      // actions and rules, enabling necessary actions to be taken based on these parameters.
      const subscription = currentUrl.searchParams.get('subscription');
      const team = currentUrl.searchParams.get('team');
      const tenant = currentUrl.searchParams.get('tenant');
      const options = {
        authorizationParams: {
          screen_hint: 'signup',
          subscription: subscription,
          team: team,
          tenant: tenant,
        },
      };

      // Redirect to the Auth0 Universal Login page with the signup screen opened
      loginWithRedirect(options);
    } else {
      logout();
    }
    return (
      <Grid2 container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <LoadingSpinner size="large" />
      </Grid2>
    );
  };

  if (!isLoading && !user) {
    if (!error) {
      return handleRedirect();
    } else {
      return <LoginError errorMessage={error.message} handleRedirect={handleRedirect} />;
    }
  }

  if (user) {
    let comp;
    const seenWelcome = getBoolean(user.metadata, 'seenWelcome');
    const hasSetup = getBoolean(user.metadata, 'hasSetup');
    if ((!seenWelcome || !hasSetup) && pathname !== '/welcome') {
      comp = <Navigate to="/welcome" />;
    } else if (prevRoute && pathname !== prevRoute) {
      window.localStorage.removeItem(PREV_ROUTE);
      comp = <Navigate to={prevRoute} />;
    }

    return comp ? comp : element;
  } else if (isLoading) {
    return (
      <Grid2 container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <LoadingSpinner size="large" />
      </Grid2>
    );
  }
};
